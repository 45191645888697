import { useToogleFavoriteProduct } from "@/common/hooks/product/useToogleFavoriteProduct";
import { MouseEvent } from "react";
import { useTranslation } from "next-i18next";
import { useGuestModalGuard } from "@/common/hooks/guest.hooks";
import { Product } from "@/Apollo/schema";
import { Heart as HeartIcon } from "@/assets/icons/24/Heart";
import { Heading } from "@/ui/cva/Heading";
import { cn } from "@/common/utils/css.utils";

type ProductCardFavoriteButtonProps = {
  item: Product;
};

export const ProductCardFavoriteButton = ({
  item,
}: ProductCardFavoriteButtonProps) => {
  const { t } = useTranslation();
  const { guestGuard } = useGuestModalGuard();
  const { toggle } = useToogleFavoriteProduct(item);

  const isFavorite = !!item?.favorite?.id;

  const toggleFavorite = async () => {
    await toggle();
  };

  const handleFavoriteClick = async (evt: MouseEvent<HTMLButtonElement>) => {
    /** Prevent navigation to product details. */
    evt.preventDefault();

    const pass = await guestGuard({
      title: (
        <span className="flex flex-col space-y-2">
          <HeartIcon className="mx-auto h-8 w-8 text-attention-500" />
          <Heading as="h4" className="text-center">
            {t("signup-login-wall:signup-login-wall-favorite-title")}
          </Heading>
        </span>
      ),
      description: t("signup-login-wall:signup-login-wall-favorite-desc"),
      referrer: "favorites",
    });

    if (!pass) {
      return;
    }

    toggleFavorite();
  };

  return (
    <button
      aria-label="Toggle Favorite"
      onClick={handleFavoriteClick}
      className="absolute right-2 top-2 md:right-4 md:top-4"
    >
      <HeartIcon
        className={cn(
          "h-7 w-7 transition-colors duration-300 md:h-8 md:w-8",
          isFavorite
            ? "stroke-white text-attention-500"
            : "stroke-black text-white hover:stroke-attention-700 hover:text-attention-200"
        )}
      />
    </button>
  );
};
