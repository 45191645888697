import { useCreateFavoriteProduct } from "@/Apollo/hooks/product/useCreateFavoriteProduct";
import { useRemoveFavoriteProduct } from "@/Apollo/hooks/product/useRemoveFavoriteProduct";
import { ContentType } from "@/Apollo/schema";
import { ProductUnknown } from "@/common/contracts/product.contracts";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import {
  getCreateFavoriteProductOptimisticResponse,
  getRemoveFavoriteProductOptimisticResponse,
} from "@/common/utils/mutation.utils";
import addToast from "@/toast/addToast";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

export const useToogleFavoriteProduct = (product: ProductUnknown) => {
  const { t } = useTranslation();

  const [createFavorite] = useCreateFavoriteProduct({
    onError: error => {
      console.error("useToogleFavoriteProduct", error.message);

      addToast(t("listing:favorite-error-unable-to-add"), {
        appearance: "error",
      });
    },
    onCompleted: () => {
      analyticsService.trackAddToWishlist(product);
    },
    update: (cache, { data }) => {
      const { id, item } = data?.createFavorite || {};

      if (!item) {
        return;
      }

      cache.modify({
        id: cache.identify(item),
        fields: {
          favorite() {
            return { id };
          },
        },
      });
    },
    optimisticResponse: variables => {
      const {
        input: { object_id: productId, content_type },
      } = variables;

      const favoriteId = `favorite-id-${productId}-${content_type}`;
      const createdAt = new Date();

      return getCreateFavoriteProductOptimisticResponse(
        productId,
        favoriteId,
        createdAt
      );
    },
  });

  const [removeFavorite] = useRemoveFavoriteProduct({
    onError: error => {
      console.error("useToogleFavoriteProduct", error.message);

      addToast(t("common:common-favorite-error-unable-to-remove"), {
        appearance: "error",
      });
    },
    update: cache => {
      cache.modify({
        id: cache.identify(product),
        fields: {
          favorite_count() {
            return product.favorite_count ? product.favorite_count - 1 : 0;
          },
          favorite() {
            return null;
          },
        },
      });
    },
    optimisticResponse: () => getRemoveFavoriteProductOptimisticResponse(),
  });

  const toggle = useCallback(async () => {
    const favoriteId = product.favorite?.id;

    if (favoriteId) {
      await removeFavorite({
        variables: { id: favoriteId },
      });
    } else {
      await createFavorite({
        variables: {
          input: { object_id: product.id, content_type: ContentType.Product },
        },
      });
    }
  }, [createFavorite, product.favorite?.id, product.id, removeFavorite]);

  return { toggle };
};
