import {
  CreateFavoriteMutation_Mutation,
  RemoveFavoriteProductMutation_Mutation,
} from "@/Apollo/schema";

export const getCreateFavoriteProductOptimisticResponse = (
  productId: string,
  favoriteId: string,
  createdAt: Date
): CreateFavoriteMutation_Mutation => {
  return {
    __typename: "Mutation",
    createFavorite: {
      __typename: "Favorite",
      id: favoriteId,
      created: createdAt.toISOString(),
      item: {
        __typename: "Product",
        id: productId,
        favorite: {
          __typename: "Favorite",
          id: favoriteId,
        },
      },
    },
  };
};

export const getRemoveFavoriteProductOptimisticResponse =
  (): RemoveFavoriteProductMutation_Mutation => {
    return {
      __typename: "Mutation",
      removeFavorite: true,
    };
  };
