import { useAuth } from "@/auth";
import { useCallback, ReactNode } from "react";
import { showModal } from "@/ui/Modal2";
import { analyticsService } from "@/common/services/analytics/analytics.service";

type GuestModalGuardOptions = {
  title?: ReactNode;
  description?: ReactNode;
  referrer?: string;
  /**
   * If passed, this event will be prevented by the guard. Mostly to be used
   * when we want to prevent click or anchor navigation for users that are
   * not logged-in.
   */
  preventEvent?: UIEvent;
};

/**
 * Guest modal guard that return false and shows guest modal if the user is not logged-in.
 */
export const useGuestModalGuard = () => {
  const { isLoggedIn } = useAuth();

  const guestGuard = useCallback(
    async (options?: GuestModalGuardOptions) => {
      const referrer = options?.referrer;
      const preventEvent = options?.preventEvent;

      preventEvent?.preventDefault();

      if (!isLoggedIn) {
        /** Trigger 'sign_up_block' event. */
        analyticsService.trackSignUpBlock(referrer);

        const { title, description } = options ?? {};
        return await showModal("signup-login-wall", { title, description });
      }

      return true;
    },
    [isLoggedIn]
  );

  return { guestGuard };
};
