import { useMutation, MutationHookOptions } from "@apollo/client";
import mutationGQL from "@/Apollo/mutation/createFavoriteProduct.graphql";

import {
  CreateFavoriteMutation,
  CreateFavoriteMutationVariables,
} from "@/Apollo/schema";

export const useCreateFavoriteProduct = (
  options?: MutationHookOptions<
    CreateFavoriteMutation,
    CreateFavoriteMutationVariables
  >
) => {
  return useMutation(mutationGQL, options);
};
