import { useMutation, MutationHookOptions } from "@apollo/client";
import mutationGQL from "@/Apollo/mutation/removeFavoriteProduct.graphql";

import {
  RemoveFavoriteProductMutation,
  RemoveFavoriteProductMutationVariables,
} from "@/Apollo/schema";

export const useRemoveFavoriteProduct = (
  options?: MutationHookOptions<
    RemoveFavoriteProductMutation,
    RemoveFavoriteProductMutationVariables
  >
) => {
  return useMutation(mutationGQL, options);
};
