import { BunnyPicture } from "@/common/components/bunny-picture";
import { ProductImage } from "@/common/contracts/pdp.contracts";
import { cn } from "@/common/utils/css.utils";

type ProductCardImageProps = {
  className: string;
  preloadImage: boolean;
  image: ProductImage;
  alt: string;
};

const ProductCardImage = ({
  className,
  preloadImage,
  image,
  alt,
}: ProductCardImageProps) => {
  return (
    <BunnyPicture
      preload={preloadImage}
      draggable={false}
      className={cn("absolute top-0 right-0 bottom-0 left-0", className)}
      imgClassName="h-full w-full object-contain"
      src={image.url}
      width={image.width ?? 0}
      height={image.height ?? 0}
      sizes={[
        { media: "(max-width: 768px)", width: "256px" },
        { media: "(min-width: 769px)", width: "512px" },
      ]}
      alt={alt}
    />
  );
};

export default ProductCardImage;
